import React from 'react'


const FeaturedCard = ({ logo, name, description, linkName, linkURL }) => {
    return (
        <div className='bg-shell rounded-xl p-4 hover:drop-shadow-xl'>
            <a className='flex flex-col' href='https://www.technologyreview.com/2021/12/07/1041420/spacex-starship-rocket-solar-system-exploration/' target={'_blank'}>
                
                <img className='aspect-video rounded-xl hover:drop-shadow-none mb-5' src='https://wp.technologyreview.com/wp-content/uploads/2021/12/48953946911_7dae1036a2_o.jpg?fit=1616,908' />
                
                <div>
                    <h1 className='text-lg'>How SpaceX’s massive Starship rocket might unlock the solar system—and beyond</h1>
                    <h2 className='font-light text-gray-900 justify-self-start ws:text-2xl mt-3'>Jonathan O'Callaghan | Dec 7, 2021</h2>
                    <h2 className='font-light text-gray-900 justify-self-start ws:text-2xl'>MIT Technology Review</h2>

                </div>
            </a>
        </div>
    )
}

export default FeaturedCard